import {useRef, useState} from 'react';
import styled from 'styled-components';
import {Container} from '../Container/Container';
import {Link, useLocation} from 'react-router-dom';
import Logo from '../../assets/img/logo.png';
import { ReactComponent as Hamburger } from '../../assets/icons/hamburger.svg';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import {Button} from '../Button/Button';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const StyledNavBar = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    background-color: var(--color-green);
    padding: 18px 0;
    
    @media screen and (max-width: 1024px) {
        padding: 14px 0;
    }
`;

const StyledLogo = styled.img`
    display: block;
    max-width: 282px;
    width: 100%;
    max-height: 50px;
    height: 100%;
    
    @media screen and (max-width: 1024px) {
        max-width: 189px;
        max-height: 32px;
    }
`;

const MenuItem = styled(Link)`
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-navigation);
    color: ${props => props.$active ? 'var(--color-orange)' : 'var(--color-white)'};
    line-height: 1.5;
    margin: 0;
    text-decoration: none;
    
    &:hover {
        color: var(--color-orange);
    }
    
    &:not(:last-of-type) {
        margin: 0 24px 0 0;
    }
    
    @media screen and (max-width: 1024px) {
        font-weight: var(--fw-medium);
        width: fit-content;
        
        &:not(:last-of-type) {
            margin: 0 0 10px 0;
        }
    }
`;

const StyledButton = styled(Button)`
    margin: 20px 0 30px;
`;

const SubItem = styled(MenuItem)`
    font-weight: var(--fw-medium);
    font-size: var(--fs-navigation-sub);
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Content = styled.div`

    @media screen and (max-width: 1024px) {
        position: fixed;
        top: 60px;
        right: ${props => props.$isOpen ? '0' : '100%'};
        background-color: var(--color-green);
        width: 100%;
        padding: 0 18px;
        z-index: 98;
        transition: right .2s ease;
        min-height: 100vh;
        height: 100%;

        @supports (min-height: -webkit-fill-available) {
            min-height: -webkit-fill-available;
        }
    }
`;

const Menu = styled.div`
    display: flex;
    
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        border-top: 1px solid var(--color-green-100);
        padding-top: 50px;
    }
`;

const NavBar = ({activeSection}) => {
    const {pathname} = useLocation();

    const [isOpen, setIsOpen] = useState(false);
    const touchActionStyle = useRef("");
    const overflowStyle = useRef("");

    const openMenu = () => {
        setIsOpen(true);
        touchActionStyle.current = document.body.style.touchAction;
        document.body.style.touchAction = "none";
        overflowStyle.current = document.body.style.overflow;
        document.body.style.overflow = "hidden";
    }

    const closeMenu = () => {
        setIsOpen(false);
        document.body.style.touchAction = touchActionStyle.current;
        document.body.style.overflow = overflowStyle.current;
    }

    const scrollToTop = () => {
        if(pathname === '/') {
            window.scrollTo(0, 10);
        }
    }

    const {width} = useWindowDimensions();
    let isMobile = width <= 1024;

    return (
        <StyledNavBar>
            <Container>

                <Flex>
                    <Link to={'/'} onClick={() => { scrollToTop(); closeMenu() }}>
                        <StyledLogo src={Logo} alt="Jurist & Werk Congres" />
                    </Link>

                    <Content $isOpen={isOpen}>
                        <Menu>
                            <MenuItem to="/#over-ons" onClick={closeMenu} $active={pathname === '/' && activeSection === 1}>Over ons</MenuItem>
                            <MenuItem to="/#congres" onClick={closeMenu} $active={pathname === '/' && activeSection === 2}>Congres 2025</MenuItem>
                            <MenuItem to="/#aanwezige-kantoren" onClick={closeMenu} $active={pathname === '/' && activeSection === 3}>Aanwezige kantoren</MenuItem>
                            <MenuItem to="/#inschrijven" onClick={closeMenu} $active={pathname === '/' && activeSection === 4}>Inschrijven</MenuItem>
                            <MenuItem to="/#media" onClick={closeMenu} $active={pathname === '/' && activeSection === 5}>Media</MenuItem>
                            <MenuItem to="/#contact" onClick={closeMenu} $active={pathname === '/' && activeSection === 6}>Contact</MenuItem>
                            {isMobile &&
                                <>
                                    <StyledButton to="#inschrijven" onClick={closeMenu}>Schrijf je in</StyledButton>
                                    <SubItem to="https://juristenwerk.nl/wp-content/uploads/2022/03/Jurist-Werk-Algemene-voorwaarden-2022.pdf" target="_blank" rel="noreferrer">Algemene voorwaarden</SubItem>
                                    <SubItem to="privacy-policy" target="_blank" rel="noreferrer">Privacyverklaring</SubItem>
                                </>
                            }
                        </Menu>
                    </Content>

                    {isMobile &&
                        <div onClick={isOpen ? closeMenu : openMenu}>
                            {isOpen ? <Close /> : <Hamburger />}
                        </div>
                    }
                </Flex>

            </Container>
        </StyledNavBar>
    )
}

export default NavBar;